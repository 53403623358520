export default [
  {
    OrgId: '1',
    PeriodId: '1',
    PeriodName: 'Pulses 1-4',
    PeriodDates: 'May 13, 2024 - June 22, 2024',
    Groups: [
      {
        GroupId: 1,
        GroupName: 'Sales Team',
      },
      {
        GroupId: 2,
        GroupName: 'Office Administration',
      },
      {
        GroupId: 3,
        GroupName: 'Warehouse Crew',
      },
      {
        GroupId: 4,
        GroupName: 'Human Resources Department',
      },
    ],
  },
  {
    OrgId: '2',
    PeriodId: '2',
    PeriodName: 'Pulses 5-8',
    PeriodDates: 'June 22, 2024 - Aug 1, 2024',
    Groups: [
      {
        GroupId: 1,
        GroupName: 'Sales Team',
      },
      {
        GroupId: 2,
        GroupName: 'Office Administration',
      },
      {
        GroupId: 3,
        GroupName: 'Warehouse Crew',
      },
      {
        GroupId: 4,
        GroupName: 'Human Resources Department',
      },
    ],
  },
  {
    OrgId: '3',
    PeriodId: '3',
    PeriodName: 'Pulses 9-12',
    PeriodDates: 'Aug 2, 2024  - Sep 11, 2024',
    Groups: [
      {
        GroupId: 1,
        GroupName: 'Sales Team',
      },
      {
        GroupId: 2,
        GroupName: 'Office Administration',
      },
      {
        GroupId: 3,
        GroupName: 'Warehouse Crew',
      },
      {
        GroupId: 4,
        GroupName: 'Human Resources Department',
      },
    ],
  },
  {
    OrgId: '4',
    PeriodId: '4',
    PeriodName: 'Pulses 13-16',
    PeriodDates: 'Sep 12, 2024 - Oct 23, 2024',
    Groups: [
      {
        GroupId: 1,
        GroupName: 'Sales Team',
      },
      {
        GroupId: 2,
        GroupName: 'Office Administration',
      },
      {
        GroupId: 3,
        GroupName: 'Warehouse Crew',
      },
      {
        GroupId: 4,
        GroupName: 'Human Resources Department',
      },
    ],
  },
]
