import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import ActionableInsightsWrapper from '../../actionable-insights/v1/views/ActionableInsightsWrapper'

export default function getActionableInsightsRoutes() {
  return (
    <Route key={'insight0'} component={authenticate(Layout)} path='actionable-insights'>
      <IndexRoute onEnter={(nextState, replace) => replace('/actionable-insights/v1')} />
      <Route key={'insight1'} path='v1/home' component={ActionableInsightsWrapper} />
    </Route>
  )
}
