import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCategoriesManageVM } from '../view-models/PulseCategoriesManageVM'
import { AppBar, Button, CssBaseline, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../utils/isIE11'
import { GrandCategoryTabVM } from '../view-models/GrandCategoryTabVM'
import './TopToolbar.scss'
import PulseSetsFilter from '../../pulse-sets/views/PulseSetsFilter'
import CheckIcon from '@material-ui/icons/Check'

interface Props {
  vm: PulseCategoriesManageVM
}

const PulseCategoriesTabs: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderGrandCategoryTabs = () => {
    const tabs = []
    vm.grandCategoryTabs.forEach((tab: GrandCategoryTabVM, i: number) => {
      tabs.push(renderCatButton(tab, i))
    })
    return tabs
  }

  const renderCatButton = (tab: GrandCategoryTabVM, i: number) => {
    if (tab.isSelected) {
      return (
        <Button
          className={`tab-btn-selected border-cat-${tab.index}`}
          key={'wd' + i}
          value={i}
          onClick={() => tab.toggleIsSelected()}
        >
          {tab.name}
          <CheckIcon className='check-icon' />
        </Button>
      )
    } else {
      return (
        <Button
          className={`tab-btn-unselected border-cat-${tab.index}`}
          key={'wd' + i}
          value={i}
          onClick={() => tab.toggleIsSelected()}
        >
          {tab.name}
        </Button>
      )
    }
  }

  const renderAddButton = () => {
    return (
      <Button
        variant='contained'
        onClick={() => vm.createNewPulseCategory()}
        className='dashboard-action'
      >
        Create New KMO Category
      </Button>
    )
  }

  const renderSetsFilter = () => {
    return <PulseSetsFilter vm={vm.pulseSetsFilterVM} />
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Typography className='dashboard-title'>Manage Pulse Categories</Typography>
          <div className='mgmt-tab-btns'>{renderGrandCategoryTabs()}</div>
          <div className='dashboard-toolbar-right'>
            {renderSetsFilter()}
            {renderAddButton()}
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}

export default inject()(observer(PulseCategoriesTabs))
