import axios from 'axios'
import env from '../../../../env'

export class ActionableInsightsService {
  private getApiUrl(): string {
    return env.var.REACT_APP_API_URL
  }

  public async getList(organizationId, token) {
    const url = this.getApiUrl() + '/actionable-insights/list'
    const body = { organizationId, sessionToken: token }
    try {
      const response = await axios.post(url, body)
      return response.data
    } catch (error) {
      console.error('Failed to fetch actionable insights list', error)
      throw error
    }
  }
}
