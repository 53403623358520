import React from 'react'
import { Button, CircularProgress, Grid, Menu, MenuItem, Select } from '@material-ui/core'
import { ActionableInsightsVM } from '../view-models/ActionableInsightsVM'
import { observer, useLocalStore } from 'mobx-react'
import './ActionableInsightsHomeScreen.scss'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { FaBolt, FaSun, FaCloudRain } from 'react-icons/fa'
import { IoSparkles } from 'react-icons/io5'

interface Props {}

const ActionableInsightsHomeScreen: React.FC<Props> = () => {
  const localStore = useLocalStore(() => ({
    vm: new ActionableInsightsVM(),
  }))
  const vm = localStore.vm
  if (!vm) return

  if (!vm.shouldRender) {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderLoadingSpinner = () => {
    if (!vm.spinnerShown) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        className='header-container'
      >
        <Grid item xs={6}>
          <h1 className='insights-header'>Actionable Insights: Climate Campaign</h1>
          <p className='insights-subheader'>Insight Period: {vm.insightPeriodDates}</p>
        </Grid>
        {renderDropdowns()}
      </Grid>
    )
  }

  const renderInsightPeriodDropdown = () => {
    return (
      <div className='left-dropdown-wrapper'>
        <span className='insights-dropdown-label'>Insight Period</span>
        <Button
          className='insights-dropdown'
          variant='outlined'
          aria-label='View Insight Periods'
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(e) => vm.openInsightPeriodMenu(e.currentTarget)}
        >
          {vm.selectedPeriodName}
        </Button>
        <Menu
          id='InsightPeriodMenu'
          className='insight-dropdown-menu'
          elevation={2}
          anchorEl={vm.insightPeriodAnchorEl}
          keepMounted
          open={vm.insightPeriodMenuShown}
          onClose={() => vm.closeInsightPeriodMenu()}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {vm.periodOptions.map((option) => (
            <MenuItem key={option.value} onClick={() => vm.setSelectedPeriod(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }

  const renderGroupDropdown = () => {
    console.log(vm.selectedGroup)
    return (
      <div className='right-dropdown-wrapper'>
        <span className='insights-dropdown-label'>Group</span>
        <Button
          className='insights-dropdown'
          variant='outlined'
          aria-label='View Groups'
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(e) => vm.openGroupMenu(e.currentTarget)}
        >
          {vm.selectedGroup?.label}
        </Button>
        <Menu
          id='GroupMenu'
          className='insight-dropdown-menu'
          elevation={2}
          anchorEl={vm.groupAnchorEl}
          keepMounted
          open={vm.groupMenuShown}
          onClose={() => vm.closeGroupMenu()}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {vm.groupOptions.map((option) => (
            <MenuItem key={option.value} onClick={() => vm.setSelectedGroup(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }

  const renderDropdowns = () => {
    if (vm.emptyStateMessageShown) return
    return (
      <>
        <Grid item xs={3}>
          {renderInsightPeriodDropdown()}
        </Grid>
        <Grid item xs={3}>
          {renderGroupDropdown()}
        </Grid>
      </>
    )
  }

  const renderSidebar = () => {
    return (
      <Grid item xs={3} className='insights-sidebar'>
        <h3 className='sidebar-header'>{vm.selectedGroup?.label}</h3>
        <p className='sidebar-subheader'>
          Innovation and collaboration fuel the creation of products that truly impact and transform
          lives.
        </p>
        <div></div>
      </Grid>
    )
  }

  const renderInsightsContent = () => {
    return (
      <Grid item xs={9}>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          className='framing-container'
        >
          <Grid item xs={3}>
            <div className='framing-inner-wrapper'>
              <div>
                <h4 className='framing-header'>Personal Framing</h4>
                <p className='framing-subheader'>
                  Building products with innovation drives real change.
                </p>
              </div>
              <div>
                <FaCloudRain className='framing-icon yellow' />
                <span className='framing-score'>32</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className='framing-text-wrapper'>
              <IoSparkles className='sparkles' />
              <p>
                Many employees express high motivation due to recent skill development opportunities
                and growth initiatives.
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderContent = () => {
    if (vm.spinnerShown) return
    if (vm.emptyStateMessageShown) return
    return (
      <>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          className='insights-legend'
        >
          <Grid item>
            <span className='legend-title'>Sentiment score:</span>
          </Grid>
          <Grid item className='legend-item-wrapper'>
            <FaBolt className='legend-icon red' />
            <span className='legend-item'>0-33</span>
          </Grid>
          <Grid item className='legend-item-wrapper' style={{ gap: '4px' }}>
            <FaCloudRain className='legend-icon yellow' />
            <span className='legend-item'>34-67</span>
          </Grid>
          <Grid item className='legend-item-wrapper' style={{ gap: '2px' }}>
            <FaSun className='legend-icon green' />
            <span className='legend-item'>68-100</span>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          className='content-container'
        >
          {renderSidebar()}
          {renderInsightsContent()}
        </Grid>
      </>
    )
  }

  const renderNoContentMessage = () => {
    if (!vm.emptyStateMessageShown) return
    return (
      <Grid item xs={12}>
        <h2 className='no-summeries-message-text'>No Actionable Insights Generated Yet</h2>
      </Grid>
    )
  }

  return (
    <div id='Insights'>
      {renderLoadingSpinner()}
      <div className='insights-container'>
        {renderHeader()}
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          {renderNoContentMessage()}
          {renderContent()}
        </Grid>
      </div>
    </div>
  )
}

export default observer(ActionableInsightsHomeScreen)
